import { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

const Main = () => {
  const { loading, user } = useOutletContext();

  useEffect(() => {
    if (!loading && (!user || (user && !user.email))) {
      window.location.href = '/login';
    }
  }, [user, loading]);

  return (
    <div className=''>
      <h1 className='text-2xl font-bold'>관리자 대시보드</h1>
      <p>관리자 페이지에 오신 것을 환영합니다.</p>
    </div>
  );
};

export default Main;
