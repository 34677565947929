import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import request from '../../lib/request';

const Users = () => {
  const { loading, user } = useOutletContext();
  const [tableData, setTableData] = useState({
    headers: [],
    bodies: [],
  });

  useEffect(() => {
    if (!loading && (!user || (user && !user.email))) {
      window.location.href = '/login';
    }

    handleGetAllUsers();
  }, [user, loading]);

  const handleGetAllUsers = async () => {
    const { result } = await request.get('/admin/users');
    if (result && result.length > 0) {
      const data = {
        headers: [],
        bodies: [],
      };
      result.forEach((res, index) => {
        let body = [];
        Object.entries(res).forEach(([key, value]) => {
          if (index === 0) {
            data.headers.push(key);
          }

          let castValue = value;

          if (key === 'userStatus') {
            switch (castValue) {
              case 'PENDING':
                castValue = '대기';
                break;
              case 'APPROVED':
                castValue = '승인';
                break;
              case 'REJECTED':
                castValue = '거절';
                break;
              case 'DELETED':
                castValue = '탈퇴';
                break;
              default:
                castValue = '알수없음';
            }
          }
          if (key === 'isAdmin') {
            castValue = castValue ? '관리자' : '-';
          }
          if (key === 'approval') {
            castValue = castValue ? '승인' : '대기';
          }
          if (key === 'recommendedBy' || key === 'statusReason') {
            castValue = castValue ? castValue : '-';
          }
          body.push(castValue);
        });
        data.bodies.push(body);
        body = [];
      });

      console.log(data);
      setTableData(data);
    }
  };

  return (
    <div className=''>
      <h1 className='text-2xl font-bold'>회원 관리</h1>
      <div className='mt-8'>
        <table className='border-collapse border border-slate-200 w-full'>
          <thead>
            <tr>
              {tableData.headers.map((header) => (
                <th
                  key={header}
                  className='border border-slate-300 bg-slate-100'
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.bodies.map((body, i) => (
              <tr
                key={i}
                className='cursor-pointer hover:bg-slate-200 transition delay-25'
              >
                {body.map((value, k) => (
                  <td
                    key={k}
                    className='text-center border border-slate-400 py-1 px-2'
                    onClick={() => (window.location.href = `/users/${body[0]}`)}
                  >
                    {value}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Users;
