import { useEffect, useState, useRef } from 'react';
import { useOutletContext, useLocation } from 'react-router-dom';
import request from '../../lib/request';
import {
  useDisclosure,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Spinner,
} from '@chakra-ui/react';
import DetailModal from './modal';
import NewModal from './newModal';

const Products = () => {
  const { loading, user } = useOutletContext();
  const { search } = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const createModal = useDisclosure();

  const initialRef = useRef(null);

  const [searchText, setSearchText] = useState('');
  const [tableData, setTableData] = useState({
    headers: [],
    bodies: [],
  });
  const [pagination, setPagination] = useState({
    total: 0,
    totalPage: 0,
    page: 0,
    count: 50,
  });
  const [selectedProduct, setSelectedProduct] = useState({});
  const [searchLoading, setSearchLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  useEffect(() => {
    if (!loading && (!user || (user && !user.email))) {
      window.location.href = '/login';
    }
  }, [user, loading]);

  useEffect(() => {
    handleGetAllProducts(search);
  }, [search]);

  const handleGetAllProducts = async (query) => {
    const { result } = await request.get(`/admin/products${query}`);
    console.log(result.products);
    if (result && result.pagination.total > 0) {
      const data = {
        headers: [],
        bodies: [],
      };
      result.products.forEach((res, index) => {
        let body = [];
        Object.entries(res).forEach(([key, value]) => {
          if (index === 0) {
            data.headers.push(key);
          }

          let castValue = value;

          if (key === 'isMapped') {
            castValue = castValue ? 'true' : 'false';
          }
          if (typeof castValue !== 'number' && !castValue) {
            castValue = '-';
          }
          body.push(castValue);
        });
        data.bodies.push(body);
        body = [];
      });

      setTableData(data);
      setPagination(result.pagination);
    }
  };

  const nextPage = () => {
    if (pagination.page + 1 <= pagination.totalPage) {
      window.location.href = `/products?page=${pagination.page + 1}&count=${
        pagination.count
      }`;
    }
  };

  const prevPage = () => {
    if (pagination.page - 1 > 0) {
      window.location.href = `/products?page=${pagination.page - 1}&count=${
        pagination.count
      }`;
    }
  };

  const handleSearchProduct = async (text) => {
    setSearchLoading(true);
    const { result } = await request.post(`/admin/products/search`, {
      search: text,
    });

    if (result.length > 0) {
      const data = {
        headers: [],
        bodies: [],
      };
      result.forEach((res, index) => {
        let body = [];
        Object.entries(res).forEach(([key, value]) => {
          if (index === 0) {
            data.headers.push(key);
          }

          let castValue = value;

          if (key === 'isMapped') {
            castValue = castValue ? 'true' : 'false';
          }
          if (typeof castValue !== 'number' && !castValue) {
            castValue = '-';
          }
          body.push(castValue);
        });
        data.bodies.push(body);
        body = [];
      });

      setTableData(data);
    }

    setSearchLoading(false);
  };

  const handleSearchProductEnter = async (e) => {
    if (e.key === 'Enter') {
      await handleSearchProduct(searchText);
    }
  };

  const handleSelectProduct = (index) => {
    const selected = {};
    tableData.headers.forEach((head, i) => {
      selected[head] = tableData.bodies[index][i];
    });
    setSelectedProduct(selected);
    onOpen();
  };

  const handleOnChangeSelectedProductDetail = (key, value) => {
    setSelectedProduct((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleUpdateProduct = async () => {
    console.log(selectedProduct);
    const { id } = selectedProduct;
    const data = {};

    setUpdateLoading(true);
    Object.entries(selectedProduct).forEach(([key, value]) => {
      if (key !== 'id' && key !== 'images' && key !== 'sellingUnit') {
        data[key] = value === '-' ? null : value;
      }
    });

    data.isMapped = data.isMapped === 'true' ? true : false;

    try {
      console.log(data);
      const { result } = await request.put(`/admin/products/${id}`, data);
      if (result !== id) {
        throw new Error('올바른 제품 정보가 아닙니다.');
      }

      alert('제품정보 업데이트 완료');
      await handleGetAllProducts(search);
      onClose();
    } catch (err) {
      console.log(err);
    }

    setUpdateLoading(false);
  };

  const handleDeleteProduct = async () => {
    const { id, itemNameKor } = selectedProduct;

    setDeleteLoading(true);
    if (window.confirm(`${id}번 "${itemNameKor}" 제품을 삭제하시겠습니까?`)) {
      try {
        await request.delete(`/admin/products/${id}`);
        alert('제품정보 삭제 완료');
        await handleGetAllProducts(search);
        onClose();
      } catch (err) {
        console.log(err);
      }
    }
    setDeleteLoading(false);
  };

  const handleOpenCreateModal = () => {
    createModal.onOpen();
  };

  return (
    <div className='h-full overflow-hidden'>
      <h1 className='text-2xl font-bold'>제품 관리</h1>
      <div className='flex items-center justify-between mt-8 mb-4 border bg-slate-200 px-4 py-2 rounded-md'>
        <div>
          <label className='mr-4'>제품 입력</label>
          <input
            type='text'
            className='rounded-md px-2 py-1'
            value={searchText}
            onChange={(e) => setSearchText(String(e.target.value))}
            onKeyUp={handleSearchProductEnter}
          />
          <button
            className='bg-black text-white px-3 py-1 ml-2 rounded-md'
            onClick={() => handleSearchProduct(searchText)}
          >
            검색
          </button>
        </div>
        <div>
          <button
            className='bg-blue-500 text-white px-3 py-1 ml-2 rounded-md'
            onClick={handleOpenCreateModal}
          >
            + 새 제품등록
          </button>
        </div>
      </div>

      <div className='h-[calc(100%-182px)] overflow-y-auto'>
        {searchLoading ? (
          <div className='flex justify-center items-center h-full'>
            <Spinner />
          </div>
        ) : (
          <TableContainer>
            <Table size='sm'>
              <Thead>
                <Tr>
                  {tableData.headers.map((header) => (
                    <Th key={header}>{header}</Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {tableData.bodies.map((body, i) => (
                  <Tr
                    key={i}
                    className='cursor-pointer hover:bg-slate-200 transition delay-25'
                    onClick={() => handleSelectProduct(i)}
                  >
                    {body.map((value, k) => (
                      <Td key={k}>{value}</Td>
                    ))}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        )}
      </div>

      <DetailModal
        initialRef={initialRef}
        isOpen={isOpen}
        onClose={onClose}
        selectedProduct={selectedProduct}
        handleOnChangeSelectedProductDetail={
          handleOnChangeSelectedProductDetail
        }
        handleUpdateProduct={handleUpdateProduct}
        handleDeleteProduct={handleDeleteProduct}
        updateLoading={updateLoading}
        deleteLoading={deleteLoading}
      />

      <NewModal
        isOpen={createModal.isOpen}
        onClose={createModal.onClose}
        handleGetAllProducts={handleGetAllProducts}
        query={search}
      />

      <div className='flex justify-center items-center text-xl gap-4'>
        <button
          onClick={prevPage}
          className='hover:bg-slate-200 w-12 h-12 rounded-full'
        >
          {'<'}
        </button>
        <div>{Boolean(pagination.page) && pagination.page}</div>
        <button
          onClick={nextPage}
          className='hover:bg-slate-200 w-12 h-12 rounded-full'
        >
          {'>'}
        </button>
      </div>
    </div>
  );
};

export default Products;
