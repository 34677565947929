import { useEffect } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';

const Mapping = () => {
  const { loading, user } = useOutletContext();
  const { id } = useParams();

  console.log(id);

  useEffect(() => {
    if (!loading && (!user || (user && !user.email))) {
      window.location.href = '/login';
    }
  }, [user, loading]);

  return (
    <div className=''>
      <h1 className='text-2xl font-bold'>맵핑 관리 ({id})</h1>
      <div className='flex items-center mt-8 mb-4'>
        <button className='bg-gray-200 border border-dashed border-gray-400 w-20 h-20 flex items-center justify-center'>
          +
        </button>
      </div>

      <form>
        <div className='mb-4'>
          <label className='block text-gray-700'>의약품 분류</label>
          <select className='w-full border border-gray-300 p-2 rounded'>
            <option value=''>선택하세요</option>
            {/* Add other options here */}
          </select>
        </div>

        <div className='mb-4'>
          <label className='block text-gray-700'>제품명</label>
          <input
            type='text'
            className='w-full border border-gray-300 p-2 rounded'
            placeholder='허가 받은 명칭으로 입력하세요'
          />
        </div>

        <div className='mb-4'>
          <label className='block text-gray-700'>제조/수입사명</label>
          <input
            type='text'
            className='w-full border border-gray-300 p-2 rounded'
            placeholder='제약회사'
          />
        </div>

        <div className='mb-4'>
          <label className='block text-gray-700'>판매사명</label>
          <input
            type='text'
            className='w-full border border-gray-300 p-2 rounded'
            placeholder='제약회사'
          />
          <label className='inline-flex items-center mt-2'>
            <input
              type='checkbox'
              className='form-checkbox'
            />
            <span className='ml-2'>제조사와 동일</span>
          </label>
        </div>

        <div className='mb-4'>
          <label className='block text-gray-700'>성분</label>
          <input
            type='text'
            className='w-full border border-gray-300 p-2 rounded'
            placeholder='한글명'
          />
          <input
            type='text'
            className='w-full border border-gray-300 p-2 rounded mt-2'
            placeholder='영문명'
          />
        </div>

        <div className='mb-4'>
          <label className='block text-gray-700'>포장단위</label>
          <input
            type='text'
            className='w-full border border-gray-300 p-2 rounded'
            placeholder='1회량'
          />
        </div>

        <div className='mb-4'>
          <label className='block text-gray-700'>사용기한</label>
          <input
            type='text'
            className='w-full border border-gray-300 p-2 rounded'
            placeholder='제조일로부터 2년'
          />
        </div>

        <div className='mb-4'>
          <label className='block text-gray-700'>효능/효과</label>
          <textarea
            className='w-full border border-gray-300 p-2 rounded'
            placeholder='입력하세요'
          ></textarea>
        </div>

        <div className='mb-4'>
          <label className='block text-gray-700'>용법 용량</label>
          <textarea
            className='w-full border border-gray-300 p-2 rounded'
            placeholder='1일 3회, 1회 1정씩'
          ></textarea>
        </div>

        <div className='mb-4'>
          <label className='block text-gray-700'>가격(부가세 포함)</label>
          <input
            type='text'
            className='w-full border border-gray-300 p-2 rounded'
            placeholder='가격'
          />
        </div>

        <div className='mb-4'>
          <label className='block text-gray-700'>가격(부가세 포함)</label>
          <div className='flex items-center'>
            <input
              type='text'
              className='w-full border border-gray-300 p-2 rounded'
              placeholder='기본가'
            />
            <input
              type='text'
              className='w-full border border-gray-300 p-2 rounded ml-4'
              placeholder='할인가'
            />
            <input
              type='text'
              className='w-full border border-gray-300 p-2 rounded ml-4'
              placeholder='할인율(%)'
            />
          </div>
        </div>

        <div className='mb-4'>
          <label className='block text-gray-700'>권장 소비자가</label>
          <input
            type='text'
            className='w-full border border-gray-300 p-2 rounded'
            placeholder='가격'
          />
        </div>

        <div className='mb-4'>
          <label className='block text-gray-700'>재고 수량</label>
          <input
            type='text'
            className='w-full border border-gray-300 p-2 rounded'
            placeholder='숫자 입력'
          />
        </div>

        <div className='flex justify-between'>
          <button
            type='button'
            className='bg-gray-200 text-gray-700 py-2 px-4 rounded'
          >
            취소
          </button>
          <div>
            <button
              type='button'
              className='bg-gray-500 text-white py-2 px-4 rounded mr-2'
            >
              임시저장
            </button>
            <button
              type='submit'
              className='bg-blue-500 text-white py-2 px-4 rounded'
            >
              저장
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Mapping;
