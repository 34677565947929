import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import Layout from './layout';
import LoginLayout from './layout/login';
import ErrorPage from './page/error';
import AdminLogin from './page/login';
import Main from './page/main';
import Products from './page/product';
import ProductDetail from './page/product/detail';
import Users from './page/user';
import UserDetail from './page/user/detail';
import Mappings from './page/mapping';
import MappingDetail from './page/mapping/detail';

const router = createBrowserRouter([
  {
    path: '',
    errorElement: <ErrorPage />,
    Component: Layout,
    children: [
      {
        path: '',
        element: <Main />,
      },
      {
        path: 'users',
        element: <Users />,
      },
      {
        path: 'users/:id',
        element: <UserDetail />,
      },
      {
        path: 'products',
        element: <Products />,
      },
      {
        path: 'mappings',
        element: <Mappings />,
      },
      {
        path: 'mappings/:id',
        element: <MappingDetail />,
      },
      {
        path: 'products/:id',
        element: <ProductDetail />,
      },
    ],
  },
  {
    path: 'login',
    Component: LoginLayout,
    children: [
      {
        path: '',
        element: <AdminLogin />,
      },
    ],
  },
]);

function App() {
  return (
    <ChakraProvider>
      <RouterProvider router={router} />
    </ChakraProvider>
  );
}

export default App;
