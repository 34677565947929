import { useEffect, useState, useRef } from 'react';
import { useOutletContext, useLocation } from 'react-router-dom';
import request from '../../lib/request';
import {
  useDisclosure,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Spinner,
} from '@chakra-ui/react';
import DetailModal from './modal';

const Mappings = () => {
  const { loading, user } = useOutletContext();
  const { search } = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = useRef(null);

  const [searchText, setSearchText] = useState('');
  const [tableData, setTableData] = useState({
    headers: [],
    bodies: [],
  });
  const [pagination, setPagination] = useState({
    total: 0,
    totalPage: 0,
    page: 0,
    count: 50,
  });
  const [selectedMapping, setSelectedMapping] = useState({});
  const [searchLoading, setSearchLoading] = useState(false);

  useEffect(() => {
    if (!loading && (!user || (user && !user.email))) {
      window.location.href = '/login';
    }
  }, [user, loading]);

  useEffect(() => {
    handleGetAllMappings(search);
  }, [search]);

  const handleGetAllMappings = async (query) => {
    const { result } = await request.get(`/admin/mappings${query}`);
    if (result && result.pagination.total > 0) {
      const data = {
        headers: [],
        bodies: [],
      };
      result.products.forEach((res, index) => {
        let body = [];
        Object.entries(res).forEach(([key, value]) => {
          if (index === 0) {
            data.headers.push(key);
          }

          let castValue = value;
          if (typeof castValue === 'boolean') {
            castValue = castValue ? '승인' : '대기';
          }
          if (!castValue) {
            castValue = '-';
          }
          body.push(castValue);
        });
        data.bodies.push(body);
        body = [];
      });

      console.log(data);
      setTableData(data);
      setPagination(result.pagination);
    }
  };

  const nextPage = () => {
    if (pagination.page + 1 <= pagination.totalPage) {
      window.location.href = `/mappings?page=${pagination.page + 1}&count=${
        pagination.count
      }`;
    }
  };

  const prevPage = () => {
    if (pagination.page - 1 > 0) {
      window.location.href = `/mappings?page=${pagination.page - 1}&count=${
        pagination.count
      }`;
    }
  };

  const handleSelectMapping = (index) => {
    const selected = {};
    tableData.headers.forEach((head, i) => {
      selected[head] = tableData.bodies[index][i];
    });
    setSelectedMapping(selected);
    onOpen();
  };

  const handleOnChangeSelectedMappingDetail = (key, value) => {
    setSelectedMapping((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleSearchMapping = async (text) => {
    setSearchLoading(true);
    const { result } = await request.post(`/admin/mappings/search`, {
      search: text,
    });

    if (result.length > 0) {
      const data = {
        headers: [],
        bodies: [],
      };
      result.forEach((res, index) => {
        let body = [];
        Object.entries(res).forEach(([key, value]) => {
          if (index === 0) {
            data.headers.push(key);
          }

          let castValue = value;

          if (key === 'isMapped') {
            castValue = castValue ? 'true' : 'false';
          }
          if (typeof castValue !== 'number' && !castValue) {
            castValue = '-';
          }
          body.push(castValue);
        });
        data.bodies.push(body);
        body = [];
      });

      setTableData(data);
    }

    setSearchLoading(false);
  };

  const handleSearchMappingEnter = async (e) => {
    if (e.key === 'Enter') {
      await handleSearchMapping(searchText);
    }
  };

  const handleUpdateMapping = async () => {
    const { id } = selectedMapping;
    const data = {};

    Object.entries(selectedMapping).forEach(([key, value]) => {
      if (key !== 'id' && key !== 'ulid') {
        data[key] =
          value === '-' || value === null || value === '' ? null : value;
      }
    });

    try {
      await request.put(`/admin/mappings/${id}`, data);
      alert('제품정보 업데이트 완료');
      await handleGetAllMappings(search);
      onClose();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className='h-full overflow-hidden'>
      <h1 className='text-2xl font-bold'>맵핑 관리</h1>
      <div className='mt-8 mb-4 border bg-slate-200 px-4 py-2 rounded-md'>
        <div>
          <label className='mr-4'>검색</label>
          <input
            type='text'
            className='rounded-md px-2 py-1'
            value={searchText}
            onChange={(e) => setSearchText(String(e.target.value))}
            onKeyUp={handleSearchMappingEnter}
          />
          <button
            className='bg-black text-white px-3 py-1 ml-2 rounded-md'
            onClick={() => handleSearchMapping(searchText)}
          >
            검색
          </button>
        </div>
      </div>

      <div className='h-[calc(100%-182px)] overflow-y-auto'>
        {searchLoading ? (
          <div className='flex justify-center items-center h-full'>
            <Spinner />
          </div>
        ) : (
          <TableContainer>
            <Table size='sm'>
              <Thead>
                <Tr>
                  {tableData.headers.map((header) => (
                    <Th key={header}>{header}</Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {tableData.bodies.map((body, i) => (
                  <Tr
                    key={i}
                    className='cursor-pointer hover:bg-slate-200 transition delay-25'
                    onClick={() => handleSelectMapping(i)}
                  >
                    {body.map((value, k) => (
                      <Td key={k}>{value}</Td>
                    ))}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        )}
      </div>

      <DetailModal
        initialRef={initialRef}
        isOpen={isOpen}
        onClose={onClose}
        selectedMapping={selectedMapping}
        handleOnChangeSelectedMappingDetail={
          handleOnChangeSelectedMappingDetail
        }
        handleUpdateMapping={handleUpdateMapping}
      />

      <div className='flex justify-center items-center text-xl gap-4'>
        <button
          onClick={prevPage}
          className='hover:bg-slate-200 w-12 h-12 rounded-full'
        >
          {'<'}
        </button>
        <div>{Boolean(pagination.page) && pagination.page}</div>
        <button
          onClick={nextPage}
          className='hover:bg-slate-200 w-12 h-12 rounded-full'
        >
          {'>'}
        </button>
      </div>
    </div>
  );
};

export default Mappings;

// 239
