import { useState } from 'react';
import { useOutletContext } from 'react-router-dom';

const AdminLogin = () => {
  const { signIn } = useOutletContext();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleEnter = (e) => {
    if (e.key === 'Enter' && email && password) {
      signIn(email, password);
    }
  };

  return (
    <div className='flex items-center justify-center h-screen'>
      <div className='bg-white p-6 rounded-lg shadow-lg w-full max-w-sm'>
        <h2 className='text-2xl font-bold mb-6 text-center'>Admin Login</h2>
        <div>
          <div className='mb-4'>
            <label className='block text-gray-700 mb-2'>Username</label>
            <input
              type='text'
              className='w-full px-3 py-2 border border-gray-300 rounded'
              placeholder='Enter your username'
              value={email}
              onChange={(e) => setEmail(String(e.target.value))}
            />
          </div>
          <div className='mb-6'>
            <label className='block text-gray-700 mb-2'>Password</label>
            <input
              type='password'
              className='w-full px-3 py-2 border border-gray-300 rounded'
              placeholder='Enter your password'
              value={password}
              onChange={(e) => setPassword(String(e.target.value))}
              onKeyUp={handleEnter}
            />
          </div>
          <button
            type='submit'
            className='w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600 transition duration-200'
            onClick={() => signIn(email, password)}
          >
            Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
