import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Spinner,
} from '@chakra-ui/react';

const medicineDataColumnNameMap = {
  id: '데이터 아이디',
  itemBarcode: '대표코드',
  barcode: '표준코드',
  itemSeq: '품목기준코드',
  isMapped: '매핑여부',
  sellerName: '판매사',
  packageUnit: '포장단위(상세제품명)',
  manufacture: '업체명(제조사명)',
  itemNameKor: '한글상품명',
  medicineStandard: '약품규격',
  productQuantity: '제품총수량',
  formulationType: '제형구분',
  packageForm: '포장형태',
  medicineType: '전문/일반',
  classificationByAgency: '식약처분류',
  materialKor: '성분',
  effect: '효능효과',
  volume: '용법용량',
  guidance: '복약지도',
  insuranceCode: '제품코드(보험코드)',
  ingredientCode: '일반명코드(성분명코드)',
  mainIngredientCode: '주성분명',
  atcCode: 'ATC코드',
  atcIngredientCode: 'ATC성분명',
  categoryClassification: '팜챗분류',
  ingredientFeature: '성분 특징',
  formulationFeature: '제형 특징',
  etc: '비고',
  cancelDate: '취소일자',
  transferDate: '양도양수적용(공고)일자',
  transferEndDate: '양도양수종료일자',
  isSerialNumberSkipped: '일련번호생략여부',
  serialNumberSkipReason: '일련번호생략사유',
  mobilizationInThreeYears: '최근3년간유통내역',
  permissionDate: '품목허가일자',
  isSpecialMedicine: '특수관리약품구분',
  readingType: '의약품판독장비구분',
};

const DetailModal = ({
  initialRef,
  isOpen,
  onClose,
  selectedProduct,
  handleOnChangeSelectedProductDetail,
  handleUpdateProduct,
  handleDeleteProduct,
  updateLoading,
  deleteLoading,
}) => {
  return (
    <Modal
      initialFocusRef={initialRef}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>제품 상세 설정</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <FormControl>
            <FormLabel>제품 이미지</FormLabel>
            <div className='flex items-center my-4'>
              <button className='bg-gray-200 border border-dashed border-gray-400 w-20 h-20 flex items-center justify-center'>
                +
              </button>
            </div>
          </FormControl>
          {Object.entries(medicineDataColumnNameMap).map(([key, name]) => {
            if (key === 'isMapped') {
              return (
                <FormControl
                  key={key}
                  mt={4}
                >
                  <FormLabel>{name}</FormLabel>
                  <RadioGroup
                    onChange={(e) => {
                      handleOnChangeSelectedProductDetail(key, e);
                    }}
                    value={String(selectedProduct[key])}
                  >
                    <Stack direction='row'>
                      <Radio value='true'>완료</Radio>
                      <Radio value='false'>미완료</Radio>
                    </Stack>
                  </RadioGroup>
                </FormControl>
              );
            }
            if (key === 'productQuantity') {
              return (
                <FormControl
                  key={key}
                  mt={4}
                >
                  <FormLabel>{name}</FormLabel>
                  <Input
                    placeholder={name}
                    type='number'
                    value={selectedProduct[key]}
                    onChange={(e) =>
                      handleOnChangeSelectedProductDetail(key, e.target.value)
                    }
                  />
                </FormControl>
              );
            }
            if (key === 'isSerialNumberSkipped') {
              return (
                <FormControl
                  key={key}
                  mt={4}
                >
                  <FormLabel>{name}</FormLabel>
                  <Input
                    placeholder='Y 또는 N 입력'
                    value={selectedProduct[key]}
                    onChange={(e) =>
                      handleOnChangeSelectedProductDetail(key, e.target.value)
                    }
                  />
                </FormControl>
              );
            }
            return (
              <FormControl
                key={key}
                mt={4}
                isDisabled={key === 'id'}
              >
                <FormLabel>{name}</FormLabel>
                <Input
                  placeholder={name}
                  value={selectedProduct[key]}
                  onChange={(e) =>
                    handleOnChangeSelectedProductDetail(key, e.target.value)
                  }
                />
              </FormControl>
            );
          })}
        </ModalBody>

        <ModalFooter justifyContent='space-between'>
          <Button
            colorScheme='red'
            mr={3}
            onClick={handleDeleteProduct}
            isDisabled={deleteLoading}
          >
            {deleteLoading ? <Spinner /> : '삭제'}
          </Button>
          <div>
            <Button
              colorScheme='blue'
              mr={3}
              onClick={handleUpdateProduct}
              isDisabled={updateLoading}
            >
              {updateLoading ? <Spinner /> : '저장'}
            </Button>
            <Button onClick={onClose}>취소</Button>
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DetailModal;
