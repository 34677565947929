import { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Spinner,
} from '@chakra-ui/react';
import request from '../../lib/request';

const medicineDataColumnNameMap = {
  id: '데이터 아이디',
  ulid: '데이터 유니크번호',
  barCode: '일반 바코드',
  itemSeq: '제품 시퀀스번호',
  mallItemName: '온라인몰 제품명',
  ledgerItemName: '원장 제품명',
  paperLedgerItemName: '종이 원장 제품명',
  quantity: '수량',
  price: '가격',
};

const DetailModal = ({
  initialRef,
  isOpen,
  onClose,
  selectedMapping,
  handleOnChangeSelectedMappingDetail,
  handleUpdateMapping,
}) => {
  const [searchText, setSearchText] = useState('');
  const [checkedProduct, setCheckedProduct] = useState(0);
  const [tableData, setTableData] = useState({
    headers: [],
    bodies: [],
  });
  const [searchLoading, setSearchLoading] = useState(false);

  const handleCheckProduct = (id, index) => {
    setCheckedProduct(id);
    alert(
      `${id}번 제품의 바코드와 시퀀스로 맵핑되었습니다.\n확정하시려면 "저장"을 눌러주세요.`
    );
    console.log(tableData.bodies[index]);
    handleOnChangeSelectedMappingDetail('barCode', tableData.bodies[index][1]);
    handleOnChangeSelectedMappingDetail('itemSeq', tableData.bodies[index][2]);
  };

  const handleSearchProduct = async (text) => {
    setSearchLoading(true);
    const { result } = await request.post(`/admin/products/search`, {
      search: text,
    });

    if (result) {
      const searchedKeys = [
        'id',
        'barcode',
        'itemSeq',
        'images',
        'itemNameKor',
        'manufacture',
        'medicineType',
        'medicineStandard',
        'packageUnit',
        'isMapped',
      ];
      const data = {
        headers: [],
        bodies: [],
      };
      result.forEach((res, index) => {
        let body = [];
        Object.entries(res).forEach(([key, value]) => {
          if (searchedKeys.includes(key)) {
            if (index === 0) {
              data.headers.push(key);
            }

            let castValue = value;

            if (key === 'isMapped') {
              castValue = castValue ? 'true' : 'false';
            }
            if (typeof castValue !== 'number' && !castValue) {
              castValue = '-';
            }
            body.push(castValue);
          }
        });
        data.bodies.push(body);
        body = [];
      });

      setTableData(data);
    }

    setSearchLoading(false);
  };

  const handleSearchProductEnter = async (e) => {
    if (e.key === 'Enter') {
      await handleSearchProduct(searchText);
    }
  };

  const handleClose = () => {
    setSearchText('');
    setTableData({
      headers: [],
      bodies: [],
    });
    setCheckedProduct(0);
    onClose();
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      isOpen={isOpen}
      onClose={handleClose}
      size='xl'
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>맵핑 상세 설정</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <div className='mb-4 border bg-slate-200 px-4 py-2 rounded-md'>
            <div>
              <label className='mr-4'>제품 입력</label>
              <input
                type='text'
                className='rounded-md px-2 py-1'
                value={searchText}
                onChange={(e) => setSearchText(String(e.target.value))}
                onKeyUp={handleSearchProductEnter}
              />
              <button
                className='bg-black text-white px-3 py-1 ml-2 rounded-md'
                onClick={() => handleSearchProduct(searchText)}
              >
                검색
              </button>
            </div>
          </div>

          <div
            className={`${
              tableData.headers.length > 1 ? 'h-48' : ''
            } overflow-hidden`}
          >
            <div className='h-full overflow-y-auto mb-4'>
              {searchLoading ? (
                <div className='flex justify-center items-center h-full'>
                  <Spinner />
                </div>
              ) : tableData.headers.length > 1 ? (
                <TableContainer>
                  <Table size='sm'>
                    <Thead>
                      <Tr>
                        {tableData.headers.map((header) => (
                          <Th key={header}>{header}</Th>
                        ))}
                      </Tr>
                    </Thead>
                    <Tbody>
                      {tableData.bodies.map((body, i) => (
                        <Tr
                          key={i}
                          className={`hover:bg-slate-200 transition delay-25 cursor-pointer ${
                            checkedProduct === body[0] ? 'bg-slate-300' : ''
                          }`}
                          onClick={() => handleCheckProduct(body[0], i)}
                        >
                          {body.map((value, k) => (
                            <Td key={k}>{value}</Td>
                          ))}
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              ) : (
                <div>검색된 제품이 없습니다.</div>
              )}
            </div>
          </div>

          {Object.entries(medicineDataColumnNameMap).map(([key, name]) => {
            return (
              <FormControl
                key={key}
                mt={4}
                isDisabled={key === 'id' || key === 'ulid'}
              >
                <FormLabel>{name}</FormLabel>
                <Input
                  placeholder={name}
                  value={selectedMapping[key]}
                  onChange={(e) =>
                    handleOnChangeSelectedMappingDetail(key, e.target.value)
                  }
                  className={`${
                    (key === 'barCode' || key === 'itemSeq') &&
                    checkedProduct !== 0
                      ? 'border border-red-600'
                      : ''
                  }`}
                />
              </FormControl>
            );
          })}
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme='blue'
            mr={3}
            onClick={handleUpdateMapping}
          >
            저장
          </Button>
          <Button onClick={handleClose}>취소</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DetailModal;
