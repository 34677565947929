const { NODE_ENV } = process.env;
const isProduction = NODE_ENV === 'production';
const isDevelopment = NODE_ENV === 'development';

const rootConfig = { baseUrl: 'http://localhost:5001' };

const prdConfig = { baseUrl: 'https://api.pharm-chat.com' };

const devConfig = { baseUrl: 'http://localhost:5001' };

const config = Object.assign(
  rootConfig,
  isProduction ? prdConfig : isDevelopment ? devConfig : {}
);

console.log(config);

export default config;
