import { Outlet } from 'react-router-dom';
import useAuth from '../hook/useAuth';

const LoginLayout = () => {
  const { user, signIn, signOut } = useAuth();

  return (
    <div className=''>
      <Outlet context={{ user, signIn, signOut }} />
    </div>
  );
};

export default LoginLayout;
