import axios from 'axios';
import config from '../../config/env';

const METHOD = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  FETCH: 'FETCH',
  DELETE: 'DELETE',
};

const Axios = axios.create({
  baseURL: config.baseUrl,
});

class Request {
  static token;

  constructor() {
    let token;
    if (typeof window === 'object') {
      token = window.localStorage.getItem('_accessToken');
    }
    this.token = token;
  }

  setToken(token) {
    this.token = token;
  }

  async get(path, signal) {
    return await Request.asyncFetch({
      path,
      method: METHOD.GET,
      token: this.token,
      signal,
    });
  }

  async post(path, body, signal) {
    return await Request.asyncFetch({
      path,
      method: METHOD.POST,
      token: this.token,
      body,
      signal,
    });
  }

  async put(path, body, signal) {
    return await Request.asyncFetch({
      path,
      method: METHOD.PUT,
      token: this.token,
      body,
      signal,
    });
  }

  async delete(path, signal) {
    return await Request.asyncFetch({
      path,
      method: METHOD.DELETE,
      token: this.token,
      signal,
    });
  }

  async rawAxios({ path, method, data, headers, signal }) {
    return await Axios(path, {
      method,
      data,
      headers,
      signal,
    });
  }

  static async asyncFetch({ path, method, token, body, signal }) {
    const { data } = await Axios(path, {
      method,
      data: body,
      headers: token
        ? {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          }
        : { 'Content-type': 'application/json' },
      signal,
    });

    return data;
  }
}

const request = new Request();

export default request;
