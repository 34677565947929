import { Link, Outlet, useLocation } from 'react-router-dom';
import { ReactComponent as LogoSvg } from '../assets/img/logo.svg';
import useAuth from '../hook/useAuth';

const Layout = () => {
  const location = useLocation();
  const { loading, user, signIn, signOut } = useAuth();

  return (
    <div className='grid grid-cols-6 divide-x h-screen overflow-hidden'>
      <div className='px-2 py-2 flex flex-col'>
        <Link to=''>
          <LogoSvg width={96} height={36} />
        </Link>
        <nav className='mt-4'>
          <ul className='space-y-4'>
            <li
              className={`border rounded-md p-2 ${
                location.pathname === '/users'
                  ? 'border-slate-300 bg-slate-200'
                  : 'border-white'
              }`}
            >
              <Link to='users' className='block'>
                회원 관리
              </Link>
            </li>
            <li
              className={`border rounded-md p-2 ${
                location.pathname === '/products'
                  ? 'border-slate-300 bg-slate-200'
                  : 'border-white'
              }`}
            >
              <Link to='products?page=1&count=50' className='block'>
                제품 관리
              </Link>
            </li>
            <li
              className={`border rounded-md p-2 ${
                location.pathname === '/mappings'
                  ? 'border-slate-300 bg-slate-200'
                  : 'border-white'
              }`}
            >
              <Link to='mappings?page=1&count=50' className='block'>
                맵핑 관리
              </Link>
            </li>
            <li className={`border border-white rounded-md p-2 text-gray-400`}>
              <Link to='' className='block'>
                광고 관리 (준비중)
              </Link>
            </li>
          </ul>
        </nav>
        <button
          className='mt-auto border bg-slate-400 text-white py-2 rounded hover:bg-slate-600 transition duration-200'
          onClick={signOut}
        >
          Logout
        </button>
      </div>
      <div className='col-span-5 p-4 overflow-y-auto'>
        <Outlet context={{ loading, user, signIn }} />
      </div>
    </div>
  );
};

export default Layout;
