import { useEffect, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import request from '../../lib/request';

const UserDetail = () => {
  const { loading, user } = useOutletContext();
  const { id } = useParams();

  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    if (!loading && (!user || (user && !user.email))) {
      window.location.href = '/login';
    }
    if (id) {
      handleGetUserByEmail(id);
    }

    return () => {
      setUserInfo({});
    };
  }, [user, loading, id]);

  const handleGetUserByEmail = async (userId) => {
    const { result } = await request.get(`/admin/users/${userId}`);
    if (result) {
      setUserInfo(result);
    }
  };

  const handleChangeIsAdmin = (e) => {
    const value = e.target.value === 'true' ? true : false;
    setUserInfo((prev) => ({ ...prev, isAdmin: value }));
  };

  const handleChangeUserType = (e) => {
    setUserInfo((prev) => ({ ...prev, userType: e.target.value }));
  };

  const handleChangeUserStatus = (e) => {
    setUserInfo((prev) => ({ ...prev, userStatus: e.target.value }));
  };

  const handleChangeStatusReason = (e) => {
    setUserInfo((prev) => ({ ...prev, statusReason: String(e.target.value) }));
  };

  const handleChangePhoneNumber = (e) => {
    setUserInfo((prev) => ({ ...prev, phoneNumber: String(e.target.value) }));
  };

  const handleUpdateUserInfo = async (userId) => {
    const { isAdmin, userType, userStatus, statusReason } = userInfo;

    try {
      const { result } = await request.put('/admin/users', {
        id: userId,
        isAdmin,
        userType,
        userStatus,
        statusReason,
      });
      if (result !== userId) {
        throw new Error('올바른 회원 정보가 아닙니다.');
      }

      alert('회원정보 업데이트 완료');
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className=''>
      <h1 className='text-2xl font-bold'>회원 관리</h1>

      <div className='flex items-center mt-8 mb-4 gap-4'>
        <div className='bg-gray-200 w-24 h-24 flex items-center justify-center rounded-full'>
          아바타
        </div>
        <div className='border divide-y rounded-md'>
          <div className='flex divide-x'>
            <div className='font-bold w-24 px-1'>ID</div>
            <div className='px-1'>{userInfo.id}</div>
          </div>
          <div className='flex divide-x'>
            <div className='font-bold w-24 px-1'>이메일</div>
            <div className='px-1'>{userInfo.email}</div>
          </div>
          <div className='flex divide-x'>
            <div className='font-bold w-24 px-1'>이름</div>
            <div className='px-1'>{userInfo.name}</div>
          </div>
          <div className='flex divide-x'>
            <div className='font-bold w-24 px-1'>유저코드</div>
            <div className='px-1'>{userInfo.userCode}</div>
          </div>
        </div>
      </div>

      <div className='max-w-[50%] mb-4 space-y-2'>
        <div className='border rounded-md flex divide-x'>
          <div className='font-bold w-24 px-1'>유저 타입</div>
          <select
            value={userInfo.userType}
            onChange={handleChangeUserType}
            className='flex-1 rounded-r-md'
          >
            <option value='OWNER_PHARMACIST'>OWNER_PHARMACIST</option>
            <option value='SALES'>SALES</option>
            <option value='STUDENT'>STUDENT</option>
            <option value='PAY_PHARMACIST'>PAY_PHARMACIST</option>
            <option value='SALES_ADMIN'>SALES_ADMIN</option>
            <option value='SUPER_ADMIN'>SUPER_ADMIN</option>
          </select>
        </div>
        <div className='border rounded-md flex divide-x'>
          <div className='font-bold w-24 px-1'>관리자</div>
          <select
            value={String(userInfo.isAdmin)}
            onChange={handleChangeIsAdmin}
            className='flex-1 rounded-r-md'
          >
            <option value='true'>관리자</option>
            <option value='false'>일반유저</option>
          </select>
        </div>
        <div className='border rounded-md flex divide-x'>
          <div className='font-bold w-24 px-1'>가입 상태</div>
          <select
            className='flex-1 rounded-r-md'
            value={userInfo.userStatus}
            onChange={handleChangeUserStatus}
          >
            <option value='APPROVED'>APPROVED</option>
            <option value='REJECTED'>REJECTED</option>
            <option value='PENDING'>PENDING</option>
            <option value='DELETED'>DELETED</option>
          </select>
        </div>
        <div className='border rounded-md flex divide-x'>
          <div className='font-bold w-24 px-1'>상태 이유</div>
          <input
            type='text'
            value={userInfo.statusReason ?? ''}
            placeholder='가입 상태 변경 이유 또는 빈값'
            onChange={handleChangeStatusReason}
            className='flex-1 px-1 rounded-r-md'
          />
        </div>
        <div className='border rounded-md flex divide-x'>
          <div className='font-bold w-24 px-1'>핸드폰 번호</div>
          <input
            type='text'
            value={userInfo.phoneNumber ?? ''}
            placeholder='변경할 핸드폰 번호 입력'
            onChange={handleChangePhoneNumber}
            className='flex-1 px-1 rounded-r-md'
          />
        </div>
      </div>

      <div className='flex items-center gap-2'>
        <div>
          <button
            type='submit'
            className='bg-blue-500 text-white py-2 px-4 rounded'
            onClick={() => handleUpdateUserInfo(userInfo.id)}
          >
            저장
          </button>
        </div>
        <button
          type='button'
          className='bg-gray-200 text-gray-700 py-2 px-4 rounded'
          onClick={() => (window.location.href = '/users')}
        >
          취소
        </button>
      </div>
    </div>
  );
};

export default UserDetail;
